import React from 'react'

import { Pages } from '../enums'

import siteData from '../content/data.json'

import DefaultLayout from '../templates/DefaultLayout'
import Section from '../components/Section'
import Container from '../components/Container'
import Paragraph from '../components/Paragraph'
import Characteristic from '../components/Characteristic'
import Usps from '../components/Usps'
import Gallery from '../components/Gallery'
import Addresses from '../components/Addresses'
import Hint from '../components/Hint'

import '../scss/styles.scss'

const Coxcolumnar = () => {
  return (
    <DefaultLayout
      title={siteData.coxcolumnar.title}
      description={siteData.coxcolumnar.description}
      activePage={Pages.COXCOLUMNAR}
    >
      <Section className="headline">
        <Container>
          <h1 className="title">{siteData.coxcolumnar.headline}</h1>
          <h2 className="subheadline">{siteData.coxcolumnar.subheadline}</h2>
          <Paragraph className="protection">
            {siteData.coxcolumnar.protection}
          </Paragraph>
        </Container>
      </Section>

      <Usps
        imageSrc={`../${siteData.coxcolumnar.uspImg}`}
        usps={siteData.coxcolumnar.usps}
      />

      <Section className="infos">
        <Container>
          <Paragraph>
            Die neue Apfelsorte <strong>„Coxcolumnar“ ®</strong> ist ein
            schwachwachsender Säulenapfel, der nur wenige kurze Seitentriebe
            ausbildet. Im Kleingarten beansprucht er deshalb auch nur wenig
            Platz. Falls die Seitenzweige auf guten Böden zu stark werden, kann
            man sie auf 2 bis 3 Augen zurückschneiden, um die schlanke Baumform
            zu erhalten. Der Baum ist schwachwachsend und wird nur etwa 2 m
            groß.
          </Paragraph>
          <Paragraph className="is-last">
            Das Besondere an dem Säulenapfel <strong>„Coxcolumnar“ ®</strong>{' '}
            ist der hervorragende Geschmack, der auf der mehrfachen Einkreuzung
            der Apfelsorte mit dem besten Aroma - der Cox Orangen Renette -
            zurückzuführen ist. Er ist in mehreren Kreuzungsschritten und nach
            Auswahl der gewünschten Formen in geschmacklicher und wuchsmäßiger
            Hinsicht entstanden.
          </Paragraph>
        </Container>
      </Section>

      <Section className="characteristics">
        <Container>
          <Characteristic title="Frucht">
            Leuchtend rot gefärbt, Fruchtgröße klein bis mittelgroß (im
            Durchschnitt 70 g), „Coxcolumnar“ ® ist ein sehr attraktiver Baum
            zum Zeitpunkt der Blüte, aber auch im August, - übervoll mit roten
            Früchten - hat er eine große Schmuckwirkung.
          </Characteristic>
          <Characteristic title="Geschmack">
            Typisches feines würziges Cox-Aroma, Fruchtfleisch knackig, lange
            lagerfähig auch ohne Kühlung. Der Apfel wird von Kindern bevorzugt.
          </Characteristic>
          <Characteristic title="Reifezeit">
            Ende August bis Anfang September.
          </Characteristic>
          <Characteristic title="Besonderheit">
            Der Apfel bleibt auch zum Zeitpunkt der Reife am Baum hängen und
            kann nach und nach gepflückt werden. Der Baum hat mit den schönen
            rot gefärbten Früchten und dem starken Behang einen hohen Zierwert.
          </Characteristic>
          <Characteristic title="Bestäubung">
            Alle gängigen Apfelsorten sind als Bestäuber geeignet.
          </Characteristic>
          <Characteristic title="Krankheiten">
            Nicht anfällig gegen Schorf und Mehltau.
          </Characteristic>
          <Characteristic title="Ertrag" isLast>
            Der Baum ist sehr blühwillig, er blüht bereits im 2. Jahr nach der
            Veredlung. Er dünnt sich selbst sehr gut aus, so dass keine
            Alternanz eintritt. Der Ertrag ist hoch.
          </Characteristic>
        </Container>
      </Section>

      <Section className="gallery">
        <Container>
          <Gallery images={siteData.coxcolumnar.images} />
        </Container>
      </Section>

      <Hint hint={siteData.coxcolumnar.hints[0]} />

      <Addresses
        title={siteData.coxcolumnar.mailOrderTitle}
        type="mailOrder"
        addresses={siteData.coxcolumnar.mailOrder}
      />

      <Hint
        hint={siteData.coxcolumnar.hints[1]}
        hintUrl={siteData.coxcolumnar.hintUrl.url}
      />

      <Addresses
        title={siteData.coxcolumnar.licenseesTitle}
        type="licensees"
        addresses={siteData.coxcolumnar.licensees}
      />
    </DefaultLayout>
  )
}

export default React.memo(Coxcolumnar)
